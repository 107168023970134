.container-fluid
{
	@include container-fluid;
}

.content-holder
{
	position: relative;
}

.renewal-holder
{
	margin-bottom: 30px;
}

@media screen and (min-width: 1200px) {
	.container-fluid
	{
		max-width: 1200px;
	}
}

.separate-double{
	margin-bottom: 120px;
}
.separate-bigger{
	margin-bottom: 90px;
}
.separate-base{
	margin-bottom: 60px;
}
.separate-small{
	margin-bottom: 30px;
}
.separate-smaller{
	margin-bottom: 15px;
}
.bg-white{
	background-color: white !important;
}
.bg-light-gray{
	background-color: #e8e8e8 !important;
}
.no-bg{
	background: none !important;
}

.PeventsNone{
	pointer-events: none !important;
}

@include palette("primary", $main-color);
@include palette("secondary", $second-color);