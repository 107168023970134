.button
{
	color: #000;
	border: 1px solid #000;
	border-radius: 3px;
	padding: 6px 10px;
	display: inline-block;
	font-size: 14px;
  
	&.button-danger
	{
		color: #FFF;
		background: #FF1921;
		border: 1px solid #FF1921;
  }
  
  &.button-search
  {
      color: #88B58D;
      padding: 10px 20px 10px 15px;
      background-color: #F5F5F5;
      border: none;
      border-radius: none;
      cursor: pointer;

      > .icon-search
      {
          font-size: 15px;
          color: #88B58D;
      }
  }

  @include rwd-width(455)
  {
      &.button-search
      {
          > .icon-search
          {
              width: 29px;
              height: 29px;
          }
      }
  }

  @include md
  {
      &.button-search
      {
          margin-left: 4px;
          padding: 10px 19px;
      }
  }

  @include rwd-width(1278)
  {
      &.button-search
      {
          margin-left: 6px;
      }
  }
}

.select-track
{
  position: relative;
  
    select
    {
        padding: 10px 0;
        width: 100%;
        padding-left: 20px;
        padding-right: 46px;
        cursor: pointer;
        background-color: #F5F5F5;
        border: none;
        color: #5B422D;
    }

    &:after
    {
        content: "\e902";
        font-size: 6px;
        position: absolute;
        top: calc(50% - 3px);
        right: 20px;
        pointer-events: none;
        font-family: 'icomoon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #5B422D;
    }

    @include lg
    {
        select
        {
            padding-left: 21px;
        }

        &:after
        {
            right: 22px;
        }
    }
}

//Hamburger Button
.hamburger {
  background: #FFF;
  width: 28px;
  height: 28px;
  position: relative;
  bottom: 2px;
  border-radius: 50%;
  padding: 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  border: 0;
  margin: 0;
  overflow: visible;
  outline: 0; }

.hamburger-box {
  width: 17px;
  height: 11px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 17px;
    height: 3px;
    background-color: #DA0020;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -5px; }
  .hamburger-inner::after {
    bottom: -5px; }

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex .hamburger-inner::after {
    transition-property: bottom, transform; }

.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(90deg); }

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex-r .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex-r .hamburger-inner::after {
    transition-property: bottom, transform; }

.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg); }



  .btn-loading{
    background-image: url('../../images/loading.gif');
    color: transparent !important;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }