.section-text {
  margin: 0 0 5%;
  p {
    line-height: 1.5em;
    margin-bottom: 0.5em;
    margin-top: 1em;
    font-size: 15px;
  }
  .address {
    margin-bottom: 5%;
  }
  ul {
    li {
      list-style: circle;
    }
  }
  ol {
    li {
      list-style: decimal;
    }
  }
}
.separate-bottom {
  margin-bottom: 4%;
}
