.carrinho {
  .carrinho__title {
    border-bottom: 2px solid #2b425b;
    line-height: 1.5;
  }
  .btn.btn-primary {
    background-color: #2b425b;
    &:hover, &.active {
      background-color: darken(#2b425b, 10%);
    }
  }
}
.carrinho__lista__cabecalho {
  border-bottom: 1px solid #acb8c1;
  font-size: 1.2em;
  color: #2b425b;
}
.carrinho__lista__item {
  border-bottom: 1px solid #acb8c1;
  padding-top: 1em;
  padding-bottom: 1em;
  font-size: 1.1em;
  color: #192432;
  .item__produto__imagem {
    img {
      max-width: 100%;
    }
  }
  .item__produto__texto {
    color: #192432;
    a {
      color: inherit;
      &:hover {
        text-decoration: underline;
        color: #2b425b;
      }
    }
  }
}
.lista__item__quantidade {
  input {
    max-width: 4em;
  }
}