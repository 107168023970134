.owl-carousel {
  z-index: 0 !important;
  .owl-prev {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 10px;
    display: block !important;
  }

  .owl-next {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 10px;
  }

  .owl-nav {
    color: $main-color;
    font-size: 60px;

    > button {
      &:hover {
        background-color: transparent !important;
        color: #006499 !important;
      }
    }
  }
  .owl-dots {
    position: absolute;
    bottom: 2%;
    width: 100%;
  }
  &.owl-carousel-4 {
    .item {
      padding: 24px 10px;
    }
  }
  &.owl-carousel-header {
    margin-top: -20px;
    .item {
      img {
        min-height: 110px;
        object-fit: cover;
      }
    }
    @media screen and (max-width: 374px) {
      .item{
        img{
          min-height: 90px;
        }
      }
    }
    @media screen and (min-width: 768px) {
      .item{
        img{
          height: 215px;
        }
      }
    }
    @media screen and (min-width: 1024px) {
      .item{
        img{
          height: 285px;
        }
      }
    }
    @media screen and (min-width: 1100px) {
      .item{
        img{
          height: 300px;
        }
      }
    }
    .owl-nav {
      padding: 0;
      .owl-prev {
        left: 10px;
      }

      .owl-next {
        right: 10px;
      }
    }
    .owl-dots {
      position: absolute;
      bottom: 2%;
      width: 100%;
    }
  }
  @media screen and (min-width: 1270px) {
    .owl-nav {
      padding: 0;
      .owl-prev {
        left: -20px;
      }

      .owl-next {
        right: -20px;
      }
    }
  }
  @media screen and (max-width: 720px) {
    .owl-nav {
      font-size: 40px;
    }
  }
}
