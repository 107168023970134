.loading-wrapper
{
	display: block;
	position: absolute;
	right: 50px;
	opacity: 0;
	transition: opacity .3s ease-in-out;
	background-color: #F5F5F5;
}

.lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 34px;
	height: 34px;
	margin: 0 auto;
}
.lds-ellipsis div {
	position: absolute;
	top: 17px;
	width: 4px;
	height: 4px;
	border-radius: 50%;
	background: #222222;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
	left: 6px;
	animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
	left: 6px;
	animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
	left: 18px;
	animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
	left: 30px;
	animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}
@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(12px, 0);
	}
}