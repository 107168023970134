.login-container
{
    min-height: 100vh;
    background-color: #222222;
    padding: 0 15px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .image-holder
    {
        margin: 0px 0 50px;
        text-align: center;

        img
        {
            max-width: 299px;
            width: 100%;
            height: auto;
        }
    }

    .logo-bottom-holder
    {
        text-align: center;
        margin: 50px 0 0;

        img
        {
            max-width: 200px;
            height: auto;
        }
    }

    @media screen and (min-width: 768px)
    {
        .image-holder
        {
            margin: 0px 0 50px;
        }

        .logo-bottom-holder
        {
            margin: 90px 0 0;
        }
    }
}

.login-form-holder
{
    margin: 0 auto;
    max-width: 450px;
    width: 100%;

    > form
    {
        .form-group
        {
            margin-bottom: 15px;

            .form-control
            {
                width: 100%;
                color: #EAE2D8;
                padding: 10px;
                font-size: 15px;
                line-height: 20px;
                border: none;
                background-color: transparent;
                border-bottom: 1px solid #9B9B9B;

                &::placeholder
                {
                    color: #FFF;
                }
            }

            .btn
            {
                background-color: #D5454A;
                border: 1px solid #006499;
                border-radius: 0px !important;
                color: #FFF;
                text-transform: uppercase;
                font-size: 18px;
                display: block;
                width: 100%;
                text-align: center;
                line-height: 24px;
                padding: 8px 0;
                font-weight: 300;
                transition: all .3s linear;
                cursor: pointer;

                &:hover
                {
                    color: #006499;
                    background-color: #FFF !important;
                }
            }

            .link-small
            {
                display: block;
                text-align: center;
                font-size: 14px;
                color: #FFF;
                text-decoration: underline;
                margin-top: 16px;
            }

            &:last-child
            {
                margin-bottom: 0px;
            }
        }
    }

    #frm_login {
        margin-bottom: 30px;
    }
}
.help-block-profile {
    color: #a94442;
    white-space: nowrap;
    font-size: 12px;
}
