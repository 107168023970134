.card {
  &.card-course {
    transition: 0.3s;
    &:hover {
      transform: scale(1.02);
    }
    .img-card {
      border-bottom: 1px solid #eae2d8;
    }

    .card-title {
      padding: 0 20px;
      margin-bottom: 20px;

      .uc-author-title {
        font-size: 11px;
        min-height: 15px;
      }
      .uc-title {
        margin: 15px 0 5px;
        padding: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        height: 46px;
        line-height: 22px;
        font-size: 18px;
      }
    }

    .information-course {
      padding: 0 20px;
      min-height: 87px;
    }
    .footer-sell {
      padding: 10px 20px 15px;
      .footer-price{
        font-size: 1.5em;
      }
    }
  }
}

.information-course {
  &._module {
    display: flex;
    flex-wrap: wrap;
    p {
      margin: 10px;
      &:first-child{
        margin-left: 0;
      }
    }
  }
  i {
    margin-right: 5px;
    font-weight: bold;
  }
  p {
    margin-bottom: 5px;
    font-size: 1.2em;
  }
}
.descr-header {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  height: 35px;
  margin-bottom: 0;
}

.footer-sell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &._flex-end {
    justify-content: flex-end;
  }
  .footer-price {
    margin: 0;
    span {
      display: block;
      font-size: 10px;
      text-decoration: line-through;
    }
  }
  .footer-action-sell {
    .btn {
      margin: 0;
    }
    a {
      display: block;
      text-align: center;
      width: 100%;
    }
  }
}

@media screen and (min-width: 768px) {
  .footer-sell {
    .footer-action-sell {
      .btn {
        font-size: 1.5em;
      }
    }
  }
  .panel-informations {
    .panel-body {
      padding: 0 20px;
    }
    .footer-sell {
      .footer-price {
        font-size: 2em;
        span {
          font-size: 0.6em;
        }
      }
      .footer-action-sell {
        .btn {
          font-size: 1.6em;
        }
      }
    }
  }
}
