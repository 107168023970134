.panel-comvc {
  border: 1px solid #e5e5e5;
  padding: 20px;
  border-radius: 3px;
  background: #fff;
  .colmn-left {
    float: left;
  }
  .colmn-right {
    float: right;
  }

  &.expirate-panel {
    .expirate-date {
      text-align: center;
      margin-bottom: 30px;
      .info-circle {
        margin-right: 10px;
        background: #ff1921;
        border-radius: 50%;
        color: #fff;
        border: 3px solid #000;
        width: 41px;
        margin: 0 auto 10px;
        height: 41px;
        font-size: 23px;
        text-align: center;
        line-height: 38px;
        span {
          color: #fff;
        }
      }
      .expirate-info {
        .expirate-title {
          margin: 0;
          font-size: 17px;
          color: #205751;
        }
        .expirate-descr {
          font-size: 14px;
          color: #2b8076;
        }
      }
    }

    .renewal-now {
      text-align: center;
      width: 100%;
    }
  }

  &.panel-danger {
    border: 1px solid #ff1921;
  }
}

@media screen and (min-width: 768px) {
  .panel-comvc {
    &.expirate-panel {
      .expirate-date {
        text-align: left;
        margin-bottom: 0;
        .info-circle {
          margin: 0;
          margin-right: 10px;
          float: left;
        }
        .expirate-info {
          float: left;
          margin-top: -1px;
          .expirate-title {
            font-size: 20px;
            font-weight: 400;
          }
          .expirate-descr {
            font-size: 14px;
          }
        }
      }
      .renewal-now {
        margin-top: 3px;
        width: auto;
      }
    }
  }
}

.info-course-holder {
  border-radius: 7px;
  border: 1px solid #eae2d8;
  overflow: hidden;
  margin-bottom: 25px;
  background-color: white;
  .panel-heading{
	height: unset;
  }
  .img-course {
    margin: 0 !important;
    border-radius: 0;
  }

  .footer-sell {
    margin-top: 20px;
  }

  .uc-author-title {
    font-size: 1.5em;
	padding: 0 20px;
	margin-bottom: 0;
  }
  .panel-title {
    line-height: 1;
    margin-top: 10px;
    margin-bottom: 3px;
    font-size: 2em;
    white-space: normal;
    max-height: 64px;
    height: unset;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 10px 20px 10px 20px;
  }
}

.video-course-holder {
  .img-course {
    background-color: black;
    width: 85%;
    margin: 0 auto;
  }
}
