.footer {
  .container {
    .row-footer {
      padding: 15px 0;
      display: flex;
      align-items: flex-start;
      div {
        //   flex: 1;
      }
      h4 {
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: initial;
        color: $main-color;
      }
      .payments {
        ul {
          display: flex;
          justify-content: center;
          li {
            margin-right: 5px;
            img {
              width: 45px;
              height: 32px;
              object-fit: contain;
            }
          }
        }
      }
      .social-footer {
        ul {
          display: flex;
          justify-content: center;
          li {
            margin-right: 10px;
            i {
              background-color: $main-color;
              color: #fff;
              padding: 5px;
              font-size: 20px;
              border-radius: 5px;
            }
          }
        }
      }
      //.ttphone {
      //  p {
      //    color: #68798a;
      //    strong {
      //      color: $main-color;
      //      font-size: 18px;
      //    }
      //  }
      //}
      &.infoBottom {
        justify-content: space-between;
        border-top: 1px solid $main-color;
        margin-top: 2%;
        small {
          color: $main-color;
          a {
            color: $main-color;
          }
        }
      }
    }
  }
  @media screen and (max-width: ($screen-sm - 1)) {
    .container {
      .row-footer {
        flex-direction: column;
        text-align: center;
        align-items: center;
        &.infoBottom {
          flex-direction: column;
        }
      }
    }
  }
  @media screen and (min-width: $screen-sm) {
    .container {
      .row-footer {
        flex-wrap: wrap;
        justify-content: flex-start;
        > div {
          width: 33.3%;
          text-align: center;
        }
      }
    }
  }
  @media screen and (min-width: $screen-md) {
    .container {
      .row-footer {
        justify-content: space-between;
        > div {
          width: initial;
          text-align: initial;
        }
      }
    }
  }
}
