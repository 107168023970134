h1,
h2,
h3,
h4,
h5,
h6 {
  &.title-comvc {
    color: #000;
  }
}
.underlined-title {
  font-size: 30px;
  color: $main-color;
  margin-bottom: 4%;
  border-bottom: 2px solid $main-color;
}

p,
span {
  &.text-comvc {
    color: #000;
  }

  &.text-tree-description {
    font-size: 10px;
    padding: 0 15px;
    color: #5b422d;

    &.text-position-bottom {
      width: 280px;
      position: absolute;
      text-align: right;
      bottom: 15px;
      right: 15px;
      margin-bottom: 0px;
    }
  }
}

.text-info-course{
  font-size: 16px;
  line-height: 1.3;
}

.text-box-alert{
  color: white !important;
  background-color: #006599;
  border-left: 6px solid #2b415b;
  width: 100%;
  padding: 10px 15px;
  border-radius: 3px;
  margin-bottom: 30px;
}

.p-with-icon{
  display: flex;
  align-items: center;
  i{
    font-size: 50px;
    margin-right: 15px;
  }
  &.color-light{
    color: #b9b9b9;
  }
}

.hidden-all{
  display: none;
}