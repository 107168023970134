.course-modules-holder {
  .module-holder {
    display: flex;
    border: 1px solid #eae2d8;
    border-radius: 5px;
    margin-bottom: 15px;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    text-align: center;
    background-color: white;
    .number-module {
      border: 1px solid transparent;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      text-align: center;
      line-height: 40px;
      font-size: 20px;
      margin: 10px 10px 20px;
      display: table;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        width: 38px;
        display: block;
        height: 38px;
        line-height: 36px;
      }
    }
    .info-modules {
      .title-info-modules {
        text-align: center;
        margin-bottom: 10px;
        font-size: 16px;
        color: #333;
        span {
          font-size: 15px;
          margin-left: 10px;
          display: inline-flex;
          align-items: center;
          span {
            border: 1px solid #333;
            height: 20px;
            width: 20px;
            line-height: 17px;
            display: inline-block;
            text-align: center;
            border-radius: 50%;
            margin-right: 5px;
          }
        }
      }
    }
    .bar-info-modules {
      p {
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          margin-right: 5px;
        }
      }
    }
    &.with-opacity {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  @media screen and (min-width: 768px) {
    .module-holder {
      flex-direction: row;
      padding: 10px 20px 10px 10px;

      .number-module {
        margin: 0 20px 0 10px;
      }

      .info-modules {
        .title-info-modules {
          font-size: 18px;
          text-align: left;
          margin-bottom: 5px;
        }

        .bar-info-modules {
          display: flex;
          p {
            margin: 0 20px 0 0;
          }
        }
      }
      .bt-course-holder {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        .btn {
          float: right;
          &:last-child {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.descr-course {
  p {
    font-size: 1.1em;
  }
}

.body-single-course .container-fluid {
  max-width: unset !important;
  > .video-holder {
    flex: 1 !important;
    max-width: 100% !important;
    @media screen and (min-width: 1024px) {
      > iframe {
        height: 80vh !important;
      }
    }
  }
}

.breadcraps {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    color: #157acc;
    margin-right: 15px;
    text-decoration: underline;
    position: relative;
    &:after {
      content: "›";
      top: 0;
      right: -11px;
      position: absolute;
      font-weight: bold;
      color: #999;
    }
  }
  @media screen and (min-width: 768px) {
    display: block;
  }
}
