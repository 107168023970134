.sidebar-course-holder {
  .separate-bordered {
    border-bottom: 1px solid #9b9b9b;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .sidebar-text {
    h3 {
      margin-top: 0;
      font-size: 16px;
    }
    p {
      text-align: justify;
      max-height: 128px;
      height: 128px;
      overflow: hidden;
      position: relative;
      margin-bottom: 0;
      transition: 0.5s;

      &:after {
        content: "";
        background: linear-gradient(
          180deg,
          rgba(245, 245, 245, 0) 0%,
          rgba(245, 245, 245, 1) 75%,
          rgba(245, 245, 245, 1) 100%
        );
        bottom: 0;
        left: 0;
        width: 100%;
        height: 80px;
        position: absolute;
      }
      &.active{
          height: unset;
          max-height: 1000px;
          &:after{
            background: unset;
          }
      }
    }
    a.read-more-text {
      display: table;
      z-index: 1000;
      position: relative;
      margin-bottom: 30px;
      color: #157acc;
      text-decoration: underline;
    }
  }

  .main-sidebar-holder {
    .main-side-course {
      margin-bottom: 15px;

      .main-header-side {
        color: #333;
        padding: 0 0 7px;
        cursor: pointer;
        i {
          margin-right: 8px;
        }
      }
      .main-body-side {
        display: flex;
        flex-direction: column;
        max-height: 0;
        overflow: hidden;
        transition: max-height 1s;
        padding-left: 21px;
        a {
          color: #157acc;
          text-decoration: underline;
          padding: 3px 0;
        }
        p {
          margin-bottom: 0;
          padding: 3px 0;
        }
      }
      &.active {
        .main-body-side {
          max-height: 1000px;
        }
      }
    }
  }
}
