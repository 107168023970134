$screen-sm: 768px;
$screen-md: 1024px;
$screen-lg: 1200px;
$color-base: #DA0020;
$color-alt: #E0221F;
$main-color: #006499;
$second-color: #2a4ea5;
//#464647
$font-size: 12px;
$font-size-sm: 10px;
$font-size-md: 16px;
$font-size-lg: 18px;
$font-family-base: 'Open Sans', sans-serif;
$font-size-base: 1.5;
$line-height-base: 24px;
$font-color-base: $second-color;
$font-height-base: 24px;
$transition: all .3s ease-in-out;