.training-category {
  .category-title {
    margin: 42px 0;
    font-size: 20px;
    line-height: 24px;
    color: $second-color;
    font-weight: bold;
    padding-bottom: 10px;
    clear: both;
    border-bottom: 2px solid $second-color;

    .icon {
      background-size: contain;
      background-position: center left;
      background-repeat: no-repeat;
      width: 39px;
      height: 45px;
      display: inline-block;
      position: relative;
      top: 4px;
    }
  }
}
